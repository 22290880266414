import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import solutionBgRingOne from '~/assets/images/case-study/rew/solution-ring-1.svg'
import solutionBgRingTwo from '~/assets/images/case-study/rew/solution-ring-2.svg'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './RecruitEverywhere.module.scss'
import useRecruitEverywhereStaticQuery from './useRecruitEverywhereStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const previewWownerTitle = 'Intelligent Job Match System'
const previewBabelTitle = 'Insurance Progressive Web App'

const quotes = {
  client: {
    name: 'Geert-Jan Waasdorp',
    position: 'Co-Founder of RecruitEverywhere',
  },
  fe: {
    name: 'Yurii',
    position: 'Front-end Developer at Codica',
  },
}

const CaseStudiesRecruitEverywhere = () => {
  const query = useRecruitEverywhereStaticQuery()

  const banner = getImage(fileToImageLikeData(query.banner))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyHead
          title="Recruitment Progressive Web Application for RecruitEverywhere"
          maxWidthTitle="553px"
          subtitle="Learn how Codica helped the customer create a global online hiring platform for recruiters and organizations."
          image={banner}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageTitle="Recruiting PWA for RecruitEverywhere"
          imageAlt="Hiring Progressive Web Application for RecruitEverywhere"
          domain="Recruiting"
          location="Rotterdam, Netherlands"
          timeline="June 2019 - April 2020"
          services={
            <>
              <Link to="/services/ui-ux-design/" key="designKey">
                UX/UI design services
              </Link>
              , Front-end and Back-end development,
              <Link
                to="/services/progressive-web-apps-development/"
                className="ml5"
                key="pwaDevKey"
              >
                PWA development services
              </Link>
            </>
          }
          team="1 Project Manager, 1 UX/UI Designers, 3 Software Developers, 1 QA Engineer"
          technologies="React, Rails API, Next.js, AWS, Redux"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“The core idea of RecruitEverywhere.com was to build a cross-platform recruiting PWA app to help recruiters and companies quickly hire staff members globally.',
            },
            {
              text: 'We were seeking an experienced development team who could help us test our idea and provide us with a reliable solution. Luckily, a friend introduced us to Codica.',
            },
            {
              text: 'When we were choosing a vendor, Codica showed expertise in building cross-platform PWAs and their ability to provide a full-cycle development process, API integration, knowledge of databases and algorithms which are all important to us.',
            },
            {
              text: 'Now that our custom recruiting platform is delivered, I can say that we are very satisfied with the expert knowledge shown by Codica team, as well as their clear development process and timely communication.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-wowner.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="Intelligence Group is a Netherlands firm that specializes in the labor market and recruitment. For more than 15 years, it has been conducting labor market research to provide their customers with a highly skilled workforce."
        bgTextRight="RecruitEverywhere is an automated recruiting platform designed to help organizations hire skillful staff members from all over the world. To achieve this goal, recruiters create a company profile, publish a new vacancy, and post it on job boards localized for a specific country."
        sectionBusiness={[
          {
            label:
              'Enable organizations to hire staff members globally quickly and conveniently.',
          },
          {
            label:
              'Create a mobile-friendly recruiting application that will work fast and flawless regardless of the device.',
          },
          {
            label:
              'Provide recruiters with the most secure payment solution to pay for the translation and vacancy posting services.',
          },
          {
            label: (
              <>
                Launch quickly and save costs on
                <Link
                  to="/services/mobile-app-development/"
                  className="ml5"
                  key="linkMobileDev"
                >
                  mobile app development service
                </Link>
                .
              </>
            ),
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Created a custom recruitment platform. The solution enables recruiters to hire skillful specialists from all over the world with a couple of clicks. A fast global hiring process is ensured owing to integration with the most popular job boards.',
          },
          {
            label:
              'Helped the customer to save costs on mobile app development and reduce time to marking by implementing the PWA technology. It allowed the customer to quickly launch a cross-platform recruitment progressive web application customized for both desktop and mobile users.',
          },
          {
            label:
              'Integrated Mollie to provide users with a secure and reliable payment experience.',
          },
          {
            label:
              'We significantly improved the loading speed of the online recruitment platform with the help of Next.js, which is a free, open-source React framework.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Mobile presence was our top priority, and after many discussions with Codica, we decided to build a progressive web application. Such apps are independent of an operating system and work well both on mobile and desktop devices.',
            },
            {
              text: 'It was the right decision, as we were able to launch our product quickly and on a reasonable budget if compared to building two separate apps for iOS and Android.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-wowner.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build your custom web product?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Cross-platform recruiting PWA"
          description={[
            'Codica created a fast-loading recruitment progressive web application that can run on both mobile devices and desktops.',
            <div className="mb-2" key="descr" />,
            'This enabled the customer to save costs on mobile app development, launch quickly, and at the same time provide end-users with a great user experience.',
          ]}
          alt="Cross-platform-hiring-solution-for-RecruitEverywhere"
          imageTitle="Cross-platform recruiting software for RecruitEverywhere"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Convenient vacancy posting functionality"
          description={[
            'We built a convenient vacancy posting form to offer recruiters a better hiring experience. Organizations can fill in all required information about the applicants they need (profession, sector, experience, etc.) Also, here they create a detailed job description and specify the company benefits.',
            <div className="mb-2" key="descr" />,
            'We ensured the mobile-friendliness of the vacancy posting form. Thanks to this, a vacancy is quickly published on the RecruitEverywhere platform from any device.',
          ]}
          alt="Published vacancy on the online hiring platform"
          imageTitle="Vacancy published on the online recruiting platform"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Integration with job boards and aggregators"
          description={[
            'A vacancy is posted on both the RecruitEverywhere website and global job search platforms.',
            <div className="mb-2" key="descrOne" />,
            'After specifying a profession, location, and number of applicants, recruiters are redirected to the page with recommended job boards. Their list is defined by a specific job location, selected previously.',
            <div className="mb-2" key="descrTwo" />,
            'Each job board has a price tag for the vacancy post, as well as the total cost for posting jobs on all of them at once.',
          ]}
          alt="Job boards on the online hiring platform"
          imageTitle="Popular job boards on the online recruiting platform"
          bgRings
          bgRingImage={solutionBgRingOne}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Secure payments with Mollie integration"
          description={[
            'Companies can pay for posting and translation services by Credit Card, PayPal, and iDEAL, a dutch e-commerce payment system. Mollie API integration ensures the support of these payment options and offers a fast, and secure way to pay for posts online.',
            <div className="mb-2" key="descr" />,
            'Owing to security checks, safe data storage, and compliance with the corresponding regulations, users of the recruitment progressive web application get the best payment experience.',
          ]}
          alt="Intuitive checkout process for the online hiring platform"
          imageTitle="Seamless checkout process for the online recruitment platform"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Integration with translation service"
          description={[
            'We integrated several translation services to enable customers to post their vacancies in different languages on different job boards and job aggregators.',
            <div className="mb-2" key="descr" />,
            'Now organizations can hire highly skilled professionals regardless of their location without spending time on translation.',
          ]}
          alt="Dashboard of the online hiring platform"
          imageTitle="Dashboard of the online recruitment platform"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Statistics for a posted job opening"
          description={[
            'The intuitive dashboard shows the most important information related to the posted vacancy. To be more specific, it displays views, clicks, and the number of days the vacancy has been on display.',
            <div className="mb-2" key="descr" />,
            'All this data is presented in the form of graphs. This way, recruiters can see the info about job openings in dynamics.',
          ]}
          alt="Statistics on the vacancies posted on the online hiring platform"
          imageTitle="Statistics on the vacancies posted on the online recruitment platform"
          bgRings
          bgRingImage={solutionBgRingTwo}
          bgRingClass={containerStyles.caseStudySolution__ringTwo}
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredSeven}
          title="Optimization for search engines"
          description={[
            'Our team improved the initial page loading speed with the help of Next.js. This React framework has a number of valuable features such as route prefetching or automatic code-splitting.',
            <div className="mb-2" key="descr" />,
            'With its help, we were able to build a static, and consequently fast recruitment progressive web application. It is optimized to rank higher in search results and shows high performance.',
          ]}
          alt="SEO-optimized online recruitment platform"
          imageTitle="SEO-optimized recruiting platform"
        />
      </div>

      <div className={containerStyles.caseStudyVideo}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description="The video below shows the key functionality of the recruiting platform. The convenient dashboard shows the latest vacancies and their detailed descriptions. Apart from that, we can see the process of creating a new vacancy."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/q5hA9szBDIE"
          />
        </LazyHydrate>

        <div className={containerStyles.caseStudyQuote__bottomVideo}>
          <CaseStudyQuote
            quote={[
              {
                text: '“RecruitEverywhere offers a great approach to recruiting. The recruitment progressive web application allows hiring top talents from different corners of the world.',
              },
              {
                text: 'We were excited to participate in this globalization process by building an engaging and responsive recruitment progressive web application for this project. The solution is fast-loading and SEO-friendly thanks to Next.js usage.',
              },
              {
                text: 'Besides, it is flexible enough to be adapted in case the customer’s needs change in the future.”',
              },
            ]}
            photo={
              <StaticImage
                src="../../../../assets/images/avatars/FE-Yurii.png"
                alt={`${quotes.fe.name}, ${quotes.fe.position} at Codica`}
                title={`${quotes.fe.name}, ${quotes.fe.position}`}
                {...imageProps}
              />
            }
            bgRing={containerStyles.caseStudyQuote__bgRingDark}
            name={quotes.fe.name}
            position={quotes.fe.position}
          />
        </div>
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'React', icon: 'react' },
            { label: 'Rails API', icon: 'ror' },
            { label: 'Next.js', icon: 'nextjs' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Redux', icon: 'redux' },
          ]}
          integrations={[
            { label: 'Google Maps API', icon: 'reactGoogleMaps' },
            { label: 'SendGrid API', icon: 'sendgrid' },
            { label: 'IG API', icon: 'igapi' },
            { label: 'Brockmeyer API', icon: 'brockmeyerapi' },
            { label: 'Translate.com API', icon: 'translateapi' },
            { label: 'Mollie', icon: 'mollie' },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“I am happy to recommend Codica as a reliable team that can deliver unique and powerful software products. They used all their expertise when building our application, and often advised on the best way to implement a particular feature.',
            },
            {
              text: 'Also, we were (still) impressed by the team’s genuine interest in our product success. Codica became an integral part of our team, and we are very satisfied with our collaboration.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-wowner.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewWownerTitle}
              color="wownerColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-wowner.png"
                  alt={`Case study: ${previewWownerTitle} | Codica`}
                  title={`Case study: ${previewWownerTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="intelligent-job-matching-service"
            />
            <CaseStudyPreview
              title={previewBabelTitle}
              color="babelColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-babel.png"
                  alt={`Case study: ${previewBabelTitle} | Codica`}
                  title={`Case study: ${previewBabelTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="insurance-progressive-web-application"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesRecruitEverywhere
