import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useCSRecruitEverywhereStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      banner: file(
        relativePath: { eq: "case-study/rew/hiring-progressive-web-app.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredOne: file(
        relativePath: {
          eq: "case-study/rew/Cross-platform-hiring-solution-for-RecruitEverywhere.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredTwo: file(
        relativePath: {
          eq: "case-study/rew/published-vacancy-on-the-online-hiring-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredThree: file(
        relativePath: {
          eq: "case-study/rew/job-boards-on-the-online-hiring-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFour: file(
        relativePath: {
          eq: "case-study/rew/Seamless-checkout-process-on-the-online-hiring-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFive: file(
        relativePath: {
          eq: "case-study/rew/Dashboard-on-the-online-hiring-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSix: file(
        relativePath: {
          eq: "case-study/rew/statistics-on-the vacancies-posted-on-the-online-hiring-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSeven: file(
        relativePath: {
          eq: "case-study/rew/SEO-optimized-online-hiring-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoCaseImage: file(
        relativePath: { eq: "case-study/rew/video-case.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)
  return query
}

export default useCSRecruitEverywhereStaticQuery
