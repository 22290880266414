import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import Button from '~/components/buttons/Button'
import { cx } from '~/utils'

import * as containerStyles from './CaseStudyVideo.module.scss'

interface CaseStudyVideoProps {
  title: string
  description: ReactNode
  ringsClass?: string
  image: IGatsbyImageData | undefined
  videoLink: string
}

const CaseStudyVideo = ({
  title,
  description,
  ringsClass = '',
  image,
  videoLink,
}: CaseStudyVideoProps) => {
  const [showVideo, setShowVideo] = useState(false)
  const { inView, ref } = useInView({ rootMargin: '400px', triggerOnce: true })

  const onClick = () => {
    setShowVideo(true)
  }

  return (
    <div className={containerStyles.caseStudyVideo}>
      <div className="container case-wrapper-md">
        <div className="row">
          <div className="col-12">
            <h2 className={containerStyles.caseStudyVideo__title}>{title}</h2>
            <div className={containerStyles.caseStudyVideo__description}>
              {description}
            </div>
          </div>
        </div>
      </div>
      <div className="container case-wrapper-lg">
        <div className="row">
          <div className="col-12">
            <div
              ref={ref}
              className={cx(containerStyles.caseStudyVideo__wrapper, {
                [ringsClass]: inView,
              })}
            >
              {!showVideo ? (
                <Button
                  type="button"
                  name="Play"
                  onClick={onClick}
                  onKeyDown={onClick}
                  customClassName={containerStyles.caseStudyVideo__btn}
                >
                  <GatsbyImage
                    image={image as IGatsbyImageData}
                    alt="Play video case study | Codica"
                    title="Play video case study"
                  />
                </Button>
              ) : null}

              {showVideo && (
                <div className={containerStyles.caseStudyVideo__videoWrapper}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={videoLink}
                    style={{ border: 0 }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Play video case study at Codica"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudyVideo
